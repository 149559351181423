import './SearchInput.scss'

import { FilterbarContext } from '@react/Filterbar/FilterbarContext'
import { filterbarPushToDataLayer, getAnalyticsAttribute } from '@react/Filterbar/utils/filterbar-tracking'
import { cls } from '@react/utils/classname'
import { ChangeEvent, KeyboardEvent, useContext, useEffect, useState } from 'react'

import { SelectedFilter } from '../FiltersTypes'

type SearchInputProps = {
  label: string
  multiselect?: boolean
  filterKey?: string
  type?: string
  selectedFilter?: SelectedFilter
  onFilterResults?: (key: string, value: string[]) => void
  isLoading?: boolean
}

export function SearchInput({ label, filterKey, type, selectedFilter, onFilterResults, isLoading }: SearchInputProps) {
  const [inputValue, setInputValue] = useState('')
  const { analyticsState, componentName, onFilterChange } = useContext(FilterbarContext)

  useEffect(() => {
    setInputValue(selectedFilter?.value[0] || '')
  }, [selectedFilter])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    if (type === 'filterResults') {
      onFilterResults(filterKey, [event.target.value])
    }
  }

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && type !== 'filterResults') {
      onFilterChange({ key: filterKey, value: inputValue.length ? [inputValue] : [] })
      filterbarPushToDataLayer({
        data: { label, property: filterKey, interactionType: 'search' },
        analytics: analyticsState,
        selection: inputValue.length ? [inputValue] : [],
      })
    }
  }

  const handleOnClick = () => {
    if (type && type === 'filterResults') {
      return
    }
    onFilterChange({ key: filterKey, value: inputValue.length ? [inputValue] : [] })
    filterbarPushToDataLayer({
      data: { label, property: filterKey, interactionType: 'search' },
      analytics: analyticsState,
      selection: inputValue.length ? [inputValue] : [],
    })
  }

  return (
    <div
      className={cls({
        'cmp-filterbar__search-input-wrapper': true,
        'cmp-filterbar__search-input-wrapper--is-disabled': isLoading,
      })}
    >
      <button
        onClick={handleOnClick}
        data-dm={getAnalyticsAttribute({ analytics: analyticsState, componentName }) || null}
        className="cmp-filterbar__search-button"
        aria-label={label || filterKey}
      ></button>
      <input
        type="text"
        className="cmp-filterbar__search-input"
        value={inputValue}
        onKeyDown={handleOnKeyDown}
        onChange={handleOnChange}
        placeholder={label || 'Search'}
        autoComplete="off"
        data-dm={getAnalyticsAttribute({ analytics: analyticsState, componentName }, 'search') || null}
      />
    </div>
  )
}
