import { cls } from '@react/utils/classname'
import { useContext } from 'react'

import { ButtonsWrapper } from '../Components/ButtonsWrapper'
import { TeaserList } from '../Components/TeaserList'
import { FilterableListContext } from '../FilterableListContext'
import { ItemType } from '../FilterableListTypes'
import { Table } from '../UI/Table'
import { getTableHeaders, getTableRows } from '../utils/table'

export const CustomerSuccessStories = () => {
  const { responseData, propsState, translationsState } = useContext(FilterableListContext)
  const { dynamicFields, items } = responseData
  const customerSuccessStoriesStyleClass = `cmp-filterable-list__customer-success-stories-${propsState.listStyle}`
  const teaserColumnsClass = `cmp-filterable-list--${propsState.teaserColumns}-columns`

  const renderComponent = (items: ItemType[], listStyle = 'teaser') => {
    switch (listStyle) {
      case 'teaser':
        return <TeaserList buttonLabel={translationsState.readMoreLabel} items={items} />
      case 'table':
        return (
          <Table
            classNames={'cmp-filterable-list__customer-success-stories'}
            onTableRowClick={true}
            tableHeaders={getTableHeaders(dynamicFields)}
            tableRows={getTableRows(items, dynamicFields, true)}
          />
        )
      default:
        return <p>List style do not exist</p>
    }
  }
  return (
    <div className="cmp-filterable-list cmp-filterable-list___customer-success-stories-wrapper">
      <div
        className={cls({
          'cmp-filterable-list cmp-filterable-list__cards': true,
          [customerSuccessStoriesStyleClass]: true,
          [teaserColumnsClass]: propsState.listStyle !== 'table',
          'cmp-filterable-list__table-wrapper': propsState.listStyle === 'table',
        })}
      >
        {renderComponent(items, propsState.listStyle)}
      </div>
      <ButtonsWrapper />
    </div>
  )
}
