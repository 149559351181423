/* eslint-disable max-lines */
import './Dropdown.scss'

import { ActionButton } from '@react/Filterbar/ActionButton'
import { DROPDOWN_KEYS_WITH_ALL_CHILDREN_IN_URL, RESET, SELECT } from '@react/Filterbar/constants'
import { RangeSliderItemType } from '@react/Filterbar/Filters/RangeSlider/RangeSliderTypes'
import { getAnalyticsAttribute } from '@react/Filterbar/utils/filterbar-tracking'
import { cls } from '@react/utils/classname'
import { useEffect } from 'react'

import { Checkbox } from '../Checkbox'
import { DropdownItems, SelectedFilter, SubFilterType } from '../FiltersTypes'
import { RangeSlider } from '../RangeSlider'
import { SearchInput } from '../SearchInput'
import { useDropdown } from './hooks'

type DropdownProps = {
  selectedFilter: SelectedFilter
  items: DropdownItems[]
  label: string
  dropdownKey: string
  disabled: boolean
  hasSearch?: boolean
  hasSecondaryDropdown?: boolean
  dropdownType?: 'checkbox' | 'rangeslider'
  unit?: string
  subFilters?: SubFilterType[]
  isLoading?: boolean
}

const getAriaLabel = (key: string) => `Filter toggle for: ${key}`

export function Dropdown({
  selectedFilter,
  dropdownKey,
  disabled,
  items,
  hasSearch,
  label,
  hasSecondaryDropdown,
  dropdownType = 'checkbox',
  unit,
  subFilters,
  isLoading,
}: DropdownProps) {
  const groupId = `${dropdownKey}-group-label`
  const buttonAriaLabel = getAriaLabel(label)

  const {
    analyticsState,
    componentName,
    filteredDropdownItems,
    isActiveDropdown,
    isFilterbarOpened,
    modifiedDropdownsItems,
    selectedItems,
    translationsState,
    resetRangeSliderState,
    setModifiedDropdownsItems,
    setFilteredDropdownItems,
    setSelectedItems,
    toggleDropdown,
    handleResetButton,
    handleSelectButton,
    selectedItemHandler,
    setHeaderTitle,
    setSelectedFilterState,
    setResetRangeSliderState,
    onInputFilterChange,
  } = useDropdown({ selectedFilter, dropdownKey, dropdownType, disabled })

  useEffect(() => {
    if (isFilterbarOpened && isActiveDropdown) {
      setHeaderTitle(label)
    }
  }, [isActiveDropdown])

  useEffect(() => {
    setSelectedItems(selectedFilter?.value)
  }, [JSON.stringify(selectedFilter?.value)])

  useEffect(() => {
    if (dropdownType !== 'checkbox') {
      return
    }

    const modifiedDropdowns = items?.map(dropdownItem => {
      dropdownItem.parent = null

      if (dropdownItem.children) {
        dropdownItem.children.forEach(child => {
          child.selected =
            (selectedFilter?.key === dropdownKey && selectedFilter?.value?.includes(child.key)) || selectedFilter?.value?.includes(dropdownItem.key)
          child.parent = dropdownItem.key
          return child
        })
        dropdownItem.selected = dropdownItem.children.some(child => child.selected)
      } else {
        items?.forEach(item => {
          item.selected = selectedFilter?.key === dropdownKey && selectedFilter?.value?.includes(item.key)
          return item
        })
      }

      return dropdownItem
    })

    setModifiedDropdownsItems(modifiedDropdowns)
  }, [dropdownType, items, selectedFilter])

  useEffect(() => {
    setFilteredDropdownItems(modifiedDropdownsItems)
  }, [modifiedDropdownsItems])

  useEffect(() => {
    if (dropdownType === 'rangeslider') {
      return
    }

    if (selectedItems?.length === 0) {
      const resetFilteredDropdownItems = filteredDropdownItems?.map((item: DropdownItems) => {
        item.selected = false
        item.children?.map(child => (child.selected = false))
        return item
      })
      setFilteredDropdownItems(resetFilteredDropdownItems)
      setSelectedFilterState({ key: dropdownKey, value: selectedItems, type: dropdownType })

      return
    }

    // This is for handling the case with parent filters behaving as single filter
    if (selectedItems?.length > 0) {
      const parentKeys =
        filteredDropdownItems
          ?.filter((item: DropdownItems) => item.selected)
          ?.map((parent: DropdownItems) => {
            const hasNoChildren = !parent.children || parent.children.length <= 0
            const allChildrenSelected = parent.children?.every(child => child.selected)
            if ((allChildrenSelected && !DROPDOWN_KEYS_WITH_ALL_CHILDREN_IN_URL.includes(dropdownKey)) || hasNoChildren) {
              return parent.key
            }
          })
          .filter((item: DropdownItems) => item) ?? []
      const childrenKeys =
        filteredDropdownItems
          ?.filter((item: DropdownItems) => item.selected)
          .flatMap((item: DropdownItems) => item.children ?? [])
          .filter((child: DropdownItems) => child.selected)
          .filter((item: DropdownItems) => !parentKeys.includes(item.parent))
          .map((item: DropdownItems) => item.key) ?? []

      setSelectedFilterState({ key: dropdownKey, value: [...childrenKeys, ...parentKeys], type: dropdownType })
    }
  }, [dropdownType, selectedItems])

  return (
    <>
      <label className="cmp-filterbar__dropdown-label" htmlFor={groupId}>
        {label}
      </label>
      <div
        className={cls({
          'cmp-filterbar__dropdown': true,
          'cmp-filterbar__dropdown--is-open': isActiveDropdown,
          'cmp-filterbar__dropdown--is-disabled': disabled || isLoading,
        })}
      >
        <button
          data-dm={getAnalyticsAttribute({ analytics: analyticsState, componentName }) || null}
          id={groupId}
          className="cmp-filterbar__dropdown-button"
          onClick={toggleDropdown}
          aria-label={buttonAriaLabel}
        >
          <span className="cmp-filterbar__dropdown-button--desktop-label"></span>
          <span className="cmp-filterbar__dropdown-button--mobile-label">{label}</span>
          {selectedItems?.length > 0 && (
            <span className="cmp-filterbar__dropdown-button--selected-checkboxes">{dropdownType === 'rangeslider' ? 1 : selectedItems.length}</span>
          )}
        </button>
        <div role="group" aria-labelledby={groupId} className="cmp-filterbar__dropdown-container">
          {hasSearch && (
            <div className="cmp-filterbar__filter-text">
              <SearchInput label="Input Text" type="filterResults" onFilterResults={onInputFilterChange} />
            </div>
          )}
          {dropdownType === 'checkbox' && (
            <ul className="cmp-filterbar__dropdown-checkboxes">
              {filteredDropdownItems?.map((item: DropdownItems) => (
                <Checkbox
                  buttonAriaLabel={getAriaLabel(item.value)}
                  key={item.key}
                  item={item}
                  onSelectItem={selectedItemHandler}
                  isLoading={isLoading}
                />
              ))}
            </ul>
          )}
          {hasSecondaryDropdown && <div>Secondary dropdown</div>}
          {dropdownType === 'rangeslider' && (
            <div className="cmp-filterbar__dropdown-rangeslider">
              <RangeSlider
                items={items as RangeSliderItemType[]}
                selectedFilter={selectedFilter}
                rangeSliderKey={dropdownKey}
                resetRangeSlider={resetRangeSliderState}
                setResetRangeSlider={setResetRangeSliderState}
                unit={unit}
                subFilters={subFilters}
              />
            </div>
          )}
        </div>
        <div className="cmp-filterbar__dropdown-footer">
          <ActionButton
            buttonLabel={translationsState?.filterbarButton || RESET}
            buttonAriaLabel={`${(translationsState?.filterbarButton || RESET).toUpperCase()}: ${label}`}
            buttonType={RESET}
            iconName={RESET}
            onClickHandler={handleResetButton}
          />
          <ActionButton
            buttonLabel={translationsState?.filterbarSelect || SELECT}
            buttonAriaLabel={`${(translationsState?.filterbarSelect || SELECT).toUpperCase()}: ${label}`}
            buttonType={SELECT}
            onClickHandler={handleSelectButton}
          />
        </div>
      </div>
    </>
  )
}
