import { ReactNode } from 'react'

export const EventCarouselArrows = ({ children }: { children: ReactNode }) => {
  return (
    <div className="splide__arrows">
      <button
        className="splide__arrow cmp-button splide__arrow--prev"
        type="button"
        aria-label="${config.translations.carouselPrevious}"
        data-dm="carousel.nav-next"
        data-cmp-hook-carousel="prev"
      >
        <span className="splide__arrow--prev cmp-button__icon cmp-button__icon--keyboard_arrow_left"></span>
      </button>
      {children}
      <button
        className="splide__arrow cmp-button splide__arrow--next"
        type="button"
        aria-label="${config.translations.carouselNext}"
        data-dm="carousel.nav-next"
        data-cmp-hook-carousel="next"
      >
        <span className="splide__arrow--next cmp-button__icon cmp-button__icon--keyboard_arrow_right"></span>
      </button>
    </div>
  )
}
