import './FilterTitle.scss'

type FilterTitlePropsType = {
  title: string
}

export function FilterTitle({ title }: FilterTitlePropsType) {
  return (
    title && (
      <div className="cmp-filter__title">
        <h2 className="cmp-filter__title-heading">{title}</h2>
      </div>
    )
  )
}
