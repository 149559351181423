import ImageWrapper from '@react/ImageWrapper'
import { ReactNode } from 'react'

import { ItemType } from '../FilterableListTypes'
import { Card } from '../UI/Card'

type TeaserProps = {
  additionalTemplate?: ReactNode
  buttonLabel?: string
  classes?: string
  customTitle?: ReactNode
  imageClasses?: string
  item: ItemType
  showButton?: boolean
  showDescription?: boolean
  showMetaDate?: boolean
  showMetaTag?: boolean
  showMetaTimeToRead?: boolean
  showTag?: boolean
  showViewsCount?: boolean
}

export const Teaser = ({
  additionalTemplate = null,
  buttonLabel = '',
  classes = '',
  customTitle = null,
  imageClasses = '',
  item,
  showButton = true,
  showDescription = true,
  showMetaDate = true,
  showMetaTag = true,
  showMetaTimeToRead = true,
  showTag = true,
  showViewsCount = false,
}: TeaserProps) => {
  if (showMetaDate) {
    item.dateString = item.dateString || item.date?.split(' ')?.[0]
  }

  const TeaserImageTemplate = ({ item }: { item: ItemType }) => {
    if (item.image) {
      return (
        <ImageWrapper
          modelJson={JSON.stringify(item.image)}
          classes={`cmp-filterable-list__card-image cmp-responsive-image cmp-responsive-image--lazy ${imageClasses}`}
          imageSrc={item.image.placeholder}
        />
      )
    }

    return <div className="cmp-filterable-list__card-image" />
  }

  return (
    <Card
      item={item}
      classes={classes}
      customTitle={customTitle}
      showButton={showButton}
      showDescription={showDescription}
      showMetaTag={showMetaTag}
      showMetaDate={showMetaDate}
      showMetaTimeToRead={showMetaTimeToRead}
      showTag={showTag}
      showViewsCount={showViewsCount}
      buttonLabel={buttonLabel}
      additionalTemplate={additionalTemplate}
    >
      <TeaserImageTemplate item={item} />
    </Card>
  )
}
