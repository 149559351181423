import { formatDate } from '@site/js/utils/formatDate'
import { useContext } from 'react'

import { ButtonsWrapper } from '../Components/ButtonsWrapper'
import { Teaser } from '../Components/Teaser'
import { FilterableListContext } from '../FilterableListContext'

export const Videos = () => {
  const { responseData, propsState, translationsState } = useContext(FilterableListContext)
  const { items } = responseData
  const isVideoTeaserList = () => propsState.videoteaserlist === 'true'
  const numberOfColumns = isVideoTeaserList() ? (items.length <= 3 ? items.length : 3) : 4

  const CustomTitle = ({ title }: { title: string }) => <h3 className="cmp-filterable-list__card-title">{title}</h3>

  return (
    <div className="cmp-filterable-list">
      <div className={`cmp-filterable-list__cards cmp-filterable-list__video-type cmp-filterable-list--${numberOfColumns}-columns`}>
        {items?.map((item, index) => (
          <Teaser
            item={{ ...item, dateString: formatDate(item.publicationDate, translationsState.temporal) }}
            key={`${index}-${item.title}`}
            customTitle={<CustomTitle title={item.title} />}
            showDescription={false}
            showTag={false}
            showMetaTag={item.viewCounter > 0}
            showMetaTimeToRead={false}
            showViewsCount={true}
          />
        ))}
      </div>
      <ButtonsWrapper url={propsState.powertvPageLink} linkText={'Go to Energy TV'} />
    </div>
  )
}
