import './EventCarousel.scss'

import { ItemType, PropsType, TranslationsType } from '@react/FilterableList/FilterableListTypes'
import { updateIndicators } from '@react/FilterableList/utils/event-carousel'
import { breakpointValues } from '@site/js/utils/breakpoint'
import { Options, Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { useEffect, useRef, useState } from 'react'

import { EventCarouselArrows } from './EventCarouselComponents/EventCarouselArrows'
import { EventCarouselHeader } from './EventCarouselComponents/EventCarouselHeader'
import { EventCarouselItem } from './EventCarouselComponents/EventCarouselItem'
import { EventCarouselPagination } from './EventCarouselComponents/EventCarouselPagination'

type EventCarouselType = {
  items: ItemType[]
  eventProps: PropsType
  translations: TranslationsType
}

const splideOptions: Options = {
  type: 'loop',
  fixedWidth: 'calc(33% - var(--spacing-lg))',
  gap: 'var(--spacing-xxl)',
  width: '100%',
  padding: 'var(--spacing-lg)',
  updateOnMove: true,
  rewind: true,
  pagination: false,
  perPage: 3,
  focus: 'center',
  breakpoints: {
    [breakpointValues.downSm]: {
      fixedWidth: '100%',
    },
  },
}

export const EventCarousel = ({ items, eventProps, translations }: EventCarouselType) => {
  const splider = useRef(null)
  const [carouselSlideItems, setCarouselSlideItems] = useState(null)
  const [currentSlideItem, setCurrentSlideItem] = useState(1)

  splider.current?.splide.on('move', () => {
    updateIndicators(splider.current.splide)
    setCurrentSlideItem(splider.current.splide.index + 1)
  })

  const handleGoToSlide = (index: number) => {
    if (splider.current) {
      splider.current.go(index)
    }
  }

  useEffect(() => {
    if (splider.current) {
      setCarouselSlideItems(splider.current.slides.filter((slide: HTMLLIElement) => !slide.classList.contains('splide__slide--clone')))
    }
  }, [items.length])

  useEffect(() => {
    if (splider.current) {
      updateIndicators(splider.current.splide)
    }
  }, [carouselSlideItems?.length])

  return (
    <>
      <EventCarouselHeader
        allEvents={translations.allEvents}
        anchorLabel={eventProps.anchorLabel}
        eventsPageLink={eventProps.eventsPageLink}
        title={eventProps.title}
        titleId={eventProps.titleId}
      />
      <Splide ref={splider} hasTrack={false} options={splideOptions}>
        <SplideTrack>
          {items?.map((item, index) => (
            <SplideSlide key={`${index}-${item.title}`}>
              <EventCarouselItem key={`${index}-${item.title}`} item={item} />
            </SplideSlide>
          ))}
        </SplideTrack>
        <EventCarouselArrows>
          <EventCarouselPagination
            currentSlide={currentSlideItem}
            slides={carouselSlideItems}
            goToSlide={handleGoToSlide}
            ariaLabel={translations.carouselChoseSlide}
          />
        </EventCarouselArrows>
      </Splide>
    </>
  )
}
