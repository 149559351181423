import './ListViewSwitcher.scss'

import { cls } from '@react/utils/classname'
import { useState } from 'react'

type ListViewSwitcherPropsType = {
  listViewTemplates: {
    name: string
    icon: string
  }[]
  onListViewSwitcher: (templateName: string) => void
}

const getSessionListValue = () => (sessionStorage.getItem('listView') === 'table' ? 1 : 0)

export const ListViewSwitcher = ({ listViewTemplates, onListViewSwitcher }: ListViewSwitcherPropsType) => {
  const [activeView, setActiveView] = useState(getSessionListValue())

  const onClickHandler = (name: string, index: number) => {
    setActiveView(index)
    onListViewSwitcher(name)
    sessionStorage.setItem('listView', name)
  }

  return (
    listViewTemplates && (
      <div className="cmp-list-view-switcher">
        {listViewTemplates.map((template, index) => (
          <button
            aria-label={template.name}
            key={index}
            className={cls({ 'cmp-button': true, 'is-active': index === activeView })}
            onClick={() => onClickHandler(template.name, index)}
          >
            <span className={`cmp-button__icon cmp-button__icon--${template.icon}`}></span>
          </button>
        ))}
      </div>
    )
  )
}
