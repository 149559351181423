import { Components } from '@splidejs/splide'

type SliderType = {
  root: HTMLElement
  Components: Components
  index: number
}

export const updateIndicators = (slider: SliderType) => {
  const indicators: HTMLLIElement[] = Array.from(slider.root.querySelectorAll('.cmp-carousel__pagination li:not(.splide__slide--clone)'))

  indicators?.forEach((indicator, id) => {
    const indicatorButton = indicator.querySelector('button')

    if (indicatorButton) {
      indicatorButton.ariaSelected = id === slider.index ? 'true' : null
      indicatorButton.tabIndex = id !== slider.index ? -1 : null
    }
  })
}
