import { cls } from '@react/utils/classname'

type SortTableHeaderPropsType = {
  activeSortHeader: { column: string; order: string }
  header: { sortable?: boolean; value: string; key?: string }
  handleOnCLickHeaders?: (column: string, order: string) => void
}

export const SortTableHeader = ({ activeSortHeader, header, handleOnCLickHeaders }: SortTableHeaderPropsType) => {
  const handleOnSort = (column: string, order: string) => {
    handleOnCLickHeaders(column, order)
  }
  return (
    <div className="cmp-filterable-list__table--sortable">
      <button data-dm="sort-table-asc.button" className="cmp-filterable-list__table--sortable-button" onClick={() => handleOnSort(header.key, 'ASC')}>
        <svg
          className={cls({
            'cmp-filterable-list__table--sortable-icon': true,
            'cmp-filterable-list__table--sortable-icon-up': true,
            'cmp-filterable-list__table--sortable-icon-active': activeSortHeader?.column === header.key && activeSortHeader?.order === 'ASC',
          })}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 14 7"
          xmlSpace="preserve"
        >
          <polygon className="sort-icon__icon" points="0,7 7,0 14,7 " />
        </svg>
      </button>
      <button
        data-dm="sort-table-desc.button"
        className="cmp-filterable-list__table--sortable-button"
        onClick={() => handleOnSort(header.key, 'DESC')}
      >
        <svg
          className={cls({
            'cmp-filterable-list__table--sortable-icon': true,
            'cmp-filterable-list__table--sortable-icon-down': true,
            'cmp-filterable-list__table--sortable-icon-active': activeSortHeader?.column === header.key && activeSortHeader?.order === 'DESC',
          })}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 14 7"
          xmlSpace="preserve"
        >
          <polygon className="sort-icon__icon" points="0,0 7,7 14,0 " />
        </svg>
      </button>
    </div>
  )
}
