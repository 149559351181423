import './EventListSectionHeader.scss'

import { FilterableListContext } from '@react/FilterableList/FilterableListContext'
import { useContext } from 'react'

type EventListSectionHeaderPropsType = {
  label: string
}

export const EventListSectionHeader = ({ label }: EventListSectionHeaderPropsType) => {
  const { translationsState } = useContext(FilterableListContext)
  let headerLabel: string

  switch (label) {
    case 'onDemand':
      headerLabel = translationsState.onDemandLabel
      break

    case 'unscheduled':
      headerLabel = translationsState.unscheduledEventsLabel
      break

    default:
      headerLabel = label.replace('-', ' ')
      break
  }

  return (
    <h4 id={headerLabel} className={'cmp-filterable-list__section-header cmp-title__text'}>
      {headerLabel}
    </h4>
  )
}
