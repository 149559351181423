import { SelectedFilter } from '@react/Filterbar/Filters/FiltersTypes'
import { useListWrapperProps } from '@react/ListWrapper/ListWrapperTypes'
import { isAuthorMode } from '@site/js/utils/author-mode'
import { getRequest } from '@site/js/utils/request'
import { useState } from 'react'

import { LAST_ACTIVE_FILTER } from '../constants'
import {
  arrayFromSearchQueryParams,
  getItemFromSession,
  getSearchQueryParams,
  getUrl,
  mergeAndUpdate,
  multipleSelectionHandler,
  setFiltersFromQueryParams,
  uniqueArrayElements,
} from '../helpers'

const getSessionListValue = (val: string | null): string => {
  if (val) return val
  return sessionStorage.getItem('listView') || 'teaser'
}

export const useListWrapper = (props: useListWrapperProps) => {
  const [notificationsChannel, setNotificationsChannel] = useState(null)
  const [translationsState, setTranslationsState] = useState(null)
  const [analyticsState, setAnalyticsState] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [urlParams, setUrlParams] = useState(window.location.search)
  const [isFilterbarLoaded, setIsFilterbarLoaded] = useState(false)
  const [filterFieldsState, setFilterFieldsState] = useState(null)
  const [secondaryFilterFieldsState, setSecondaryFilterFieldsState] = useState(null)
  const [totalNumber, setTotalNumber] = useState(null)
  const [offsetState, setOffsetState] = useState(['0'])
  const [calculatedOffsetState, setCalculatedOffsetState] = useState(['0'])
  const [isLoading, setIsLoading] = useState(false)
  const [filterTitle, setFilterTitle] = useState('')
  const [filterTypeState, setFilterTypeState] = useState(null)
  const [showLoadingSpinnerOnInit, setShowLoadingSpinnerOnInit] = useState(true)
  const [itemsState, setItemsState] = useState([])
  const [responseData, setResponseData] = useState(null)
  const [listStyle, setListStyle] = useState(getSessionListValue(props.listStyle))

  const prefix = `filterable${props?.listId || 0}-`

  const loadFilterableList = async (loadMoreItems = false) => {
    setIsLoading(true)
    try {
      const currentSearchQueryParams = multipleSelectionHandler(prefix).join('&')
      const url = getUrl({ currentSearchQueryParams, prefix, offset: offsetState, calculatedOffset: calculatedOffsetState, urlFromProps: props.url })
      const replaceString = new RegExp(prefix, 'gi')
      const response = await getRequest(url.replace(replaceString, ''))
      const {
        filterFields,
        secondaryFilterFields,
        totalNumber,
        calculatedOffset,
        items,
        title,
        loadMore,
        dynamicFields,
        filterType,
        jobListingUrl,
        rtuExternalLinkSelectorListConfiguration,
      } = await response.json()

      if (isAuthorMode()) {
        sessionStorage.removeItem(`${prefix}-filterFields`)
        sessionStorage.removeItem(`${prefix}-secondaryFilterFields`)
      }

      if (!loadMoreItems) {
        const savedFilterFields = filterFieldsState || (!isAuthorMode() && getItemFromSession(`${prefix}-filterFields`))
        setFilterFieldsState(
          mergeAndUpdate({ currentData: savedFilterFields, newData: filterFields, filterType, sessionFilterFieldsKey: `${prefix}-filterFields` }),
        )

        const savedSecondaryFilterFields = secondaryFilterFields || (!isAuthorMode() && getItemFromSession(`${prefix}-secondaryFilterFields`))
        setSecondaryFilterFieldsState(
          mergeAndUpdate({
            currentData: savedSecondaryFilterFields,
            newData: secondaryFilterFields,
            filterType,
            sessionFilterFieldsKey: `${prefix}-secondaryFilterFields`,
          }),
        )
      }

      setTotalNumber(totalNumber)

      if (loadMore) {
        setCalculatedOffsetState(calculatedOffset ? [`${calculatedOffset}`] : ['0'])
        setOffsetState(prevState => [`${Number(prevState[0]) + (items?.length || 0)}`])
      }

      setFilterTitle(title)
      setFilterTypeState(filterType)
      setItemsState(prevState => (loadMoreItems ? [...prevState, ...items] : items))

      const data = {
        items: loadMoreItems ? [...itemsState, ...items] : items,
        filterFields: loadMoreItems ? filterFieldsState : filterFields,
        loadMore,
        totalNumber,
        dynamicFields,
        jobListingUrl,
        rtuExternalLinkSelectorListConfiguration,
      }

      setResponseData(data)
    } catch (error) {
      setResponseData('error')
      console.error(error)
    }

    if (showLoadingSpinnerOnInit) {
      setShowLoadingSpinnerOnInit(false)
    }

    setIsLoading(false)
    sessionStorage.setItem(LAST_ACTIVE_FILTER, '')
  }

  const loadMoreData = async () => {
    await loadFilterableList(true)
  }

  const urlParamsHandler = () => {
    const queryParams = arrayFromSearchQueryParams()
    const selectedFilters = []
    if (queryParams.length) {
      setFiltersFromQueryParams({ queryParams, selectedFilters, prefix, setOffsetState, setCalculatedOffsetState })

      const isOffsetPresent = selectedFilters.some(filter => filter.key === 'offset')
      const isCalculatedOffsetPresent = selectedFilters.some(filter => filter.key === 'calculatedOffset')

      if (!isOffsetPresent) {
        selectedFilters.push({ key: 'offset', value: [offsetState] })
      }
      if (!isCalculatedOffsetPresent) {
        selectedFilters.push({ key: 'calculatedOffset', value: [calculatedOffsetState] })
      }
    } else {
      selectedFilters.push({ key: 'offset', value: ['0'] })
      selectedFilters.push({ key: 'calculatedOffset', value: ['0'] })
    }

    setIsFilterbarLoaded(false)
    setSelectedFilters(selectedFilters)
  }

  const onSelectedFiltersChange = (selectedFilters: SelectedFilter[]) => {
    const searchQueryParams = []

    selectedFilters?.forEach((filter: SelectedFilter) => {
      const key = encodeURIComponent(`${prefix}${filter.key}`)
      const value = encodeURIComponent(Array.isArray(filter.value) ? filter.value.join(',') : filter.value)

      searchQueryParams.push(`${key}=${value}`)
    })

    if (isFilterbarLoaded) {
      const uniqueSearchQueryParams = uniqueArrayElements(searchQueryParams)
      const otherQueryParams = getSearchQueryParams(prefix, false)
      const hasQueryParams = uniqueSearchQueryParams.length || otherQueryParams.length
      const searchQuery = hasQueryParams ? `?${[...uniqueSearchQueryParams, ...otherQueryParams].join('&')}` : ''
      setUrlParams(searchQuery)
      setOffsetState(['0'])
      setCalculatedOffsetState(['0'])
    }
    setIsFilterbarLoaded(true)
    setSelectedFilters(selectedFilters)
  }

  return {
    analyticsState,
    filterFieldsState,
    filterTypeState,
    isLoading,
    listStyle,
    notificationsChannel,
    secondaryFilterFieldsState,
    selectedFilters,
    filterTitle,
    prefix,
    responseData,
    showLoadingSpinnerOnInit,
    totalNumber,
    translationsState,
    urlParams,
    loadFilterableList,
    loadMoreData,
    onSelectedFiltersChange,
    setAnalyticsState,
    setListStyle,
    setNotificationsChannel,
    setTranslationsState,
    urlParamsHandler,
  }
}
