import './SubFilter.scss'

import { SubFilterSelectedItemType, SubFilterType } from '@react/Filterbar/Filters/FiltersTypes'
import { cls } from '@react/utils/classname'
import { KeyboardEvent, useEffect, useState } from 'react'

type SubFilterPropsType = {
  subFilter: SubFilterType
  selectedSubFilter?: SubFilterSelectedItemType
  resetSubFilter?: boolean
  onSelectSubFilter: (selectedSubFilter: SubFilterSelectedItemType) => void
}

export function SubFilter({ subFilter, selectedSubFilter, resetSubFilter, onSelectSubFilter }: SubFilterPropsType) {
  const [isOpenDropdown, setIsOpenDropDown] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState(subFilter?.label)

  useEffect(() => {
    if (resetSubFilter) {
      selectSubFilter('')
    }
  }, [resetSubFilter])

  useEffect(() => {
    setSelectedLabel(selectedSubFilter?.value[0] || subFilter.label)
  }, [selectedSubFilter])

  const selectSubFilter = (key: string) => {
    if (key === selectedSubFilter?.value[0]) {
      setIsOpenDropDown(false)
      return
    }
    const existingSubFilter = subFilter.items.find(item => item.key === key)
    onSelectSubFilter({ key: subFilter.key, value: existingSubFilter ? [existingSubFilter.value] : [''] })
    setIsOpenDropDown(false)
  }

  const handleOnClickOption = (key: string) => {
    selectSubFilter(key)
  }

  const handleOnKeyDownSubFiltertOption = (event: KeyboardEvent, key: string) => {
    if (event.key === ' ') {
      event.preventDefault()
      selectSubFilter(key)
    }
  }

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (event.key === ' ') {
      event.preventDefault()
      handleIsDropdownOpen()
    }
  }

  const handleIsDropdownOpen = () => setIsOpenDropDown(!isOpenDropdown)

  return (
    subFilter?.items.length > 0 && (
      <div
        className={cls({
          'cmp-subfilter': true,
          'cmp-subfilter--is-disabled': subFilter?.disabled,
        })}
      >
        <span
          className="cmp-subfilter__label"
          role="combobox"
          onClick={handleIsDropdownOpen}
          onKeyDown={handleOnKeyDown}
          aria-autocomplete="list"
          aria-expanded={isOpenDropdown}
          aria-label={subFilter.label}
          tabIndex={0}
        >
          {selectedLabel}
        </span>
        {isOpenDropdown && (
          <div className="cmp-subfilter__items-wrapper" role="listbox" aria-label="sub filter options">
            <div className="cmp-subfilter__items">
              <span className="cmp-subfilter__separator"></span>
              <div
                className={cls({
                  'cmp-subfilter__item': true,
                  'cmp-subfilter__item--is-active': !selectedSubFilter?.value[0],
                })}
                role="option"
                tabIndex={isOpenDropdown ? 0 : -1}
                onClick={() => handleOnClickOption('')}
                onKeyDown={e => handleOnKeyDownSubFiltertOption(e, '')}
              >
                {subFilter.label}
              </div>
              {subFilter.items.map(item => (
                <div
                  className={cls({
                    'cmp-subfilter__item': true,
                    'cmp-subfilter__item--is-active': selectedSubFilter?.value[0] === item.value,
                  })}
                  role="option"
                  key={item.key}
                  tabIndex={isOpenDropdown ? 0 : -1}
                  onClick={() => handleOnClickOption(item.key)}
                  onKeyDown={e => handleOnKeyDownSubFiltertOption(e, item.key)}
                >
                  {item.value}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  )
}
