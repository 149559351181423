import { capitalize } from '@site/js/utils/capitalize'

import { IndividualDate, ItemType, ModifiedEventItemsType, PropsType, TranslationsType } from '../FilterableListTypes'
import { checkDelay, formatEventDate, formatMonthFromObject, getDateAndTimeValues, isMultiDate } from './dates'

export const dateLine = (startDate: string, endDate = '', isOnDemand = false): string => {
  if (isOnDemand) {
    return ''
  }
  if (endDate && startDate.split(' ')[0] !== endDate.split(' ')[0]) {
    return `${startDate.split(' ')[0]} - ${endDate.split(' ')[0]}`
  }
  return startDate.split(' ')[0]
}

export const modifyEventItems = (items: ItemType[], propsState: PropsType, translationState: TranslationsType): ModifiedEventItemsType[] => {
  const unscheduledEvents: ModifiedEventItemsType = { label: 'unscheduled', items: items.filter(item => item.unscheduledEvent) }
  const onDemandEvents: ModifiedEventItemsType = { label: 'onDemand', items: items.filter(item => item.onDemand) }
  const eventItems = items.filter(item => !item.unscheduledEvent && !item.onDemand)
  const events: ModifiedEventItemsType[] = []
  const isUpcomingEvents = propsState.eventListType === 'upcoming_events'

  const modifiedItems = isUpcomingEvents ? sortAndModifyUpcomingEvents(eventItems, modifyIndividualDatesEvents, sortItemsByStartDate) : eventItems

  modifiedItems.forEach(item => {
    item.tagValue = checkDelay({ item, propsState, translationState, isTopEvent: false })

    const dateObject =
      item.tagValue === 'Ongoing' ? new Date(Date.now()) : new Date(item?.utcEventStartDate || getDateAndTimeValues(item.startDate).dateObj)

    const month = formatMonthFromObject({ date: dateObject, options: { month: 'long' } })
    const year = dateObject.getFullYear()
    const label = `${month}-${year}`

    const existingLabel = events?.find(item => item.label === label)

    if (existingLabel) {
      existingLabel.items.push(item)
    } else {
      events.push({ label, items: [item] })
    }
  })

  return [unscheduledEvents, onDemandEvents, ...events]
}

const modifyIndividualDatesEvents = (item: ItemType) => {
  if (item.individualDates) {
    const startAndEndDate = getDatesFromIndividualDates(item.individualDates)
    item.utcEventStartDate = startAndEndDate.utcEventStartDate
    item.utcEventEndDate = startAndEndDate.utcEventEndDate
    item.startDate = formatEventDate(new Date(startAndEndDate.utcEventStartDate))
    item.endDate = formatEventDate(new Date(startAndEndDate.utcEventEndDate))
  }

  return item
}

const sortItemsByStartDate = (a: ItemType, b: ItemType): number => (a.utcEventStartDate > b.utcEventStartDate ? 1 : -1)

const sortAndModifyUpcomingEvents = (
  eventItems: ItemType[],
  actionCallback: (item: ItemType) => ItemType,
  sortCallback: (a: ItemType, b: ItemType) => number,
): ItemType[] => eventItems.map(actionCallback).sort(sortCallback)

const getDatesFromIndividualDates = (individualDates: IndividualDate[]) => {
  const dateNow = Date.now()
  return individualDates.find(
    individualDate =>
      (individualDate.utcEventStartDate <= dateNow && dateNow <= individualDate.utcEventEndDate) || dateNow < individualDate.utcEventStartDate,
  )
}

export type EventObjectType = {
  start: string
  end?: string
}

export const getEventDateObject = (item: ItemType, translations: TranslationsType): EventObjectType => {
  const event = {
    start: undefined,
    end: undefined,
  }

  if (item.unscheduledEvent) {
    event.start = capitalize(translations.unscheduledEventsUpcomingLabel)
    return event
  }

  if (item.onDemand) {
    event.start = capitalize(translations.onDemandLabel)
    return event
  }

  const isMultiDay = isMultiDate(item.startDate, item.endDate)

  const { date: startDate, formatMonth: startMonth, year: startYear } = getDateAndTimeValues(item.startDate, 'short')
  event.start = `${startMonth} ${startDate}, ${startYear}`

  if (isMultiDay) {
    const { date: endDate, formatMonth: endMonth, year: endYear } = getDateAndTimeValues(item.endDate, 'short')
    event.end = `${endMonth} ${endDate}, ${endYear}`
  }

  return event
}
