import { cls } from '@react/utils/classname'

type ClickableCarouselPaginationType = {
  slides: HTMLLIElement[]
  handleOnClick: (index: number) => void
}

type EventCarouselPaginationProps = {
  currentSlide: number
  slides: HTMLLIElement[]
  ariaLabel: string
  goToSlide: (index: number) => void
}

const ClickableCarouselPagination = ({ slides, handleOnClick }: ClickableCarouselPaginationType) => {
  return slides?.map((slide, index) => (
    <li key={index} role="presentation" className="cmp-carousel__pagination-item" onClick={() => handleOnClick(index)}>
      <button
        className={cls({
          'cmp-carousel__pagination-item--button': true,
          'is-active': slide.classList.contains('is-active'),
        })}
        type="button"
        role="tab"
        aria-label={`Go to slide ${index + 1}`}
      ></button>
    </li>
  ))
}

const NumberedCarouselPagination = ({ slides, current }: { slides: HTMLLIElement[]; current: number }) => {
  return (
    <span className="cmp-carousel__pagination-current-slide">
      {current}/{slides.length}
    </span>
  )
}

export const EventCarouselPagination = ({ currentSlide, slides, ariaLabel, goToSlide }: EventCarouselPaginationProps) => {
  const handleOnClick = (slide: number) => goToSlide(slide)

  return (
    <ul className="cmp-carousel__pagination" role="tablist" aria-label={ariaLabel}>
      {slides?.length <= 3 && <ClickableCarouselPagination slides={slides} handleOnClick={handleOnClick} />}
      {slides?.length > 3 && <NumberedCarouselPagination slides={slides} current={currentSlide} />}
    </ul>
  )
}
