import './EventListItem.scss'

import { CalendarIcon } from '@react/FilterableList/UI/CalendarIcon'
import { EventObjectType, getEventDateObject } from '@react/FilterableList/utils/events'
import { useContext } from 'react'

import { FilterableListContext } from '../../../FilterableListContext'
import { ItemType, TranslationsType } from '../../../FilterableListTypes'
import { ListTypeItem } from '../../../UI/ListTypeItem'

type EventListItemProps = {
  item: ItemType
}

const EventTagAndCalendarIcon = ({ item, translations }: { item: ItemType; translations: TranslationsType }) => {
  return (
    <div className="cmp-filterable-list__card-tag-and-icon">
      {item.tagValue && <span className="cmp-filterable-list__card-tag-and-icon--tag-value">{item.tagValue}</span>}
      <span className="cmp-filterable-list__card-tag-and-icon--icon">
        <CalendarIcon item={item} unscheduledEventsUpcomingLabel={translations.unscheduledEventsUpcomingLabel} />
      </span>
    </div>
  )
}

const EventDateAndLocationComponent = ({ eventObject, location }: { eventObject: EventObjectType; location: string }) => {
  return (
    <div className="cmp-filterable-list__card-event-date-and-location">
      {!eventObject.end && <div className="cmp-filterable-list__card-event-date-and-location--date">{eventObject.start}</div>}
      {eventObject.end && (
        <>
          <div className="cmp-filterable-list__card-event-date-and-location--date">
            <span>from</span> {eventObject.start} <span>to</span> {eventObject.end}
          </div>
        </>
      )}
      {location && <div className="cmp-filterable-list__card-event-date-and-location--location">{location}</div>}
    </div>
  )
}

const EventTitleComponent = ({ eventObject, item }: { eventObject: EventObjectType; item: ItemType }) => {
  return (
    <>
      <EventDateAndLocationComponent eventObject={eventObject} location={item.location} />
      <h5 className="cmp-filterable-list__card-title">{item.title}</h5>
    </>
  )
}

export const EventListItem = ({ item }: EventListItemProps) => {
  const { translationsState } = useContext(FilterableListContext)
  const eventDateObject = getEventDateObject(item, translationsState)

  return (
    <>
      <ListTypeItem
        customTitle={<EventTitleComponent item={item} eventObject={eventDateObject} />}
        item={item}
        showDescription={true}
        showMetaTag={false}
        showMetaDate={false}
        showMetaTimeToRead={false}
        showButton={true}
      >
        <EventTagAndCalendarIcon item={item} translations={translationsState} />
      </ListTypeItem>
    </>
  )
}
