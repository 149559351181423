import './ActionButton.scss'

import { ReactNode, useContext } from 'react'

import { FilterableListContext } from '../FilterableListContext'

type ActionButtonType = {
  buttonType?: 'button' | 'link'
  children?: ReactNode
  icon?: string
  iconSize?: 'sm' | 'md' | 'lg'
  onClick?: () => void
  url?: string
  variant?: 'primary' | 'secondary'
}

export const ActionButton = ({
  buttonType = 'button',
  children,
  icon,
  iconSize = 'md',
  onClick,
  url,
  variant = 'secondary',
  ...rest
}: ActionButtonType) => {
  const { isLoading } = useContext(FilterableListContext)

  const renderButton = () => {
    if (buttonType === 'button') {
      return (
        <>
          <button className={`cmp-button cmp-button--${variant}-positive ${isLoading ? 'cmp-button--is-loading' : ''}`} onClick={onClick}>
            {children}
            {icon && <span className={`cmp-button__icon icon-${icon} icon--${iconSize}`} aria-hidden={true}></span>}
          </button>
        </>
      )
    }

    return (
      <a className={`cmp-button cmp-button--${variant}-positive`} href={url} {...rest}>
        {children}
        {icon && <span className={`cmp-button__icon icon-${icon} icon--${iconSize}`} aria-hidden={true}></span>}
      </a>
    )
  }

  return renderButton()
}
